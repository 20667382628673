import getConfig from 'next/config';
import useClientRuntimeConfig from 'state/useClientRuntimeConfig';
import { getCachedRuntimeConfig } from 'utils/runtimeConfigUtils';

const useApiUrl = () => {
    const { serverRuntimeConfig } = getConfig();
    const { NEXT_PUBLIC_SWAGGER_API: SERVER_API } = serverRuntimeConfig;

    const { config: clientConfig } = useClientRuntimeConfig();
    const { NEXT_PUBLIC_SWAGGER_API: CLIENT_API } = clientConfig;

    const { NEXT_PUBLIC_SWAGGER_API: CASHED_API } = getCachedRuntimeConfig();

    return SERVER_API || CLIENT_API || CASHED_API || '';
};

export default useApiUrl;
